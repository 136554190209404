.custom-margin-top {
  margin-top: 17.75rem;

}

/* .imgfull {
  background-image: url('../public/images/BG\ \(1\).jpg');
  background-size: cover;
  background-position: center;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  overflow-y: auto;
} */

.container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.my-input {
  width: 100%;
  max-width: 25rem;
  /* Use rem units for better scalability */
  margin: 0 auto;
}

.logo-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  /* Card background with transparency */
  padding: 2rem;
  /* Card padding */
  border-radius: 8px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for card effect */
  margin-bottom: 2rem;
  /* Space between logo card and form */
  /* background-image: url('../public/images/BG\ \(1\).jpg');  */
  background-size: cover;
  background-position: center;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  /* Adjust as necessary */
}

.form-container {
  width: 100%;
  max-width: 25rem;
}

/* .form-container {
  position: relative; 
  z-index: 1; 
  width: 100%;
  max-width: 400px; 
  margin: 0 auto; 
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9); 
  border-radius: 8px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
} */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}




/* Add this CSS to your stylesheet */
.navbar {
  background-color: #fff;
  padding: 0 20px;
}

.hamburger {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.hamburger span {
  /* background-color: #000; */
  display: block;
  height: 4px;
  width: 24px;
  margin: 5px 0;
  transition: all 0.3s;
}

/* .hamburger.is-active span:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.hamburger.is-active span:nth-child(2) {
  opacity: 0;
}

.hamburger.is-active span:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
} */

.menu {
  /* background-color: #2c3e50;
  padding: 5px;
  width: 180px;
  height: 100%;
  position: fixed;
  margin-left: 30px;
  border-radius: 5px;
  top: 20px;
  left: 0; */
  position: fixed;
  top: 0px;
  left: 0;
  height: 100%;
  background-color: #2c3e50;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 17px;

}

.menu-list li {

  margin-left: 20px;
  /* Add spacing between menu items */
  cursor: pointer;
  /* Change cursor to pointer on hover */
}


.menu1 {
  position: fixed;
  top: 0px;
  left: 0;
  height: 100%;
  background-color: #2c3e50;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu1 li {
  margin: 15px 0;
}

.menu1 .icon {
  color: #fff;
  font-size: 1.5rem;
}

.menu-list {
  list-style: none;
  padding: 0;
}

.menu-list .menu-label {
  color: #ecf0f1;
  font-weight: bold;
  margin-bottom: 20px;
}

.menu-list li {
  margin: 10px 0;
}

.menu-list li a {
  color: #ecf0f1;
  text-decoration: none;
}

.menu-list li a:hover {
  color: #3498db;
}

.content {
  padding: 20px;
  background-color: #ecf0f1;
  flex: 1;
}






.has-border-primary {
  position: relative;
  /* Ensure the pseudo-elements are positioned relative to this element */
  border: 1px solid #4a4a4a;
  /* Primary color */
  background-color: transparent;
  color: #4a4a4a;
  /* Primary color */
  padding: 10px;
  /* Optional padding for better text readability */
}

.has-border-primary::before {
  left: -100px;
}

.has-border-primary::after {
  right: -100px;
}


/* CSS */
body.modal-open {
  overflow: hidden;
}

.platform-details input {
  margin-top: 3px;
}


/* CSS */
body.modal-open {
  overflow: hidden;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.checkbox-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 10px;
}

.checkbox-item label {
  margin-left: 5px;
}

.platform-details {
  margin-top: 10px;
  margin-left: 20px;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;
  margin-bottom: 20px;
}

.checkbox-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 10px;
}

.checkbox-item label {
  margin-left: 5px;
}

.platform-details {
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.button.is-small-custom {
  padding: 5px 15px;
  /* Adjust padding as needed */
  font-size: 15px;
  /* Adjust font size as needed */
}

.imgfull {
  background-image: url('../public/images/BG (1).jpg');
  background-size: cover;
  background-position: center;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  overflow-y: auto;
}

.container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.my-input {
  width: 100%;
  max-width: 25rem;
  /* Use rem units for better scalability */
  margin: 0 auto;
}

.logo-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  /* Card background with transparency */
  padding: 2rem;
  /* Card padding */
  border-radius: 8px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for card effect */
  margin-bottom: 2rem;
  /* Space between logo card and form */
  /* background-image: url('../public/images/BG\ \(1\).jpg');  */
  background-size: cover;
  background-position: center;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  /* Adjust as necessary */
}

.form-container {
  width: 100%;
  max-width: 25rem;
}

.button.is-small-custom {
  padding: 5px 15px;
  /* Adjust padding as needed */
  font-size: 15px;
  /* Adjust font size as needed */
}